import React, { FC, useState } from 'react';
import { navigate } from 'gatsby';
import Layout from '@/common/ui/template/Layout';
import Header from '@/newcar/ui/common/organism/Header';
import Breadcrumb from '@/newcar/ui/common/molecule/Breadcrumb';
import ContactDescription from '@/newcar/ui/contact/organism/ContactDescription';
import ContactForm, { ContactFormData } from '@/newcar/ui/contact/organism/ContactForm';
import ScrollToTop from '@/common/ui/organism/ScrollToTop';
import axios from 'axios';
import Footer from '@/newcar/ui/common/organism/Footer';
import SEO from '@/newcar/ui/contact/organism/SEO';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from '@/pages/contact/index.module.styl';

// お問合せ送信時のエラー検知用
interface State {
  hasError: boolean
}

const Page: FC = () => {
  const [state, setState] = useState<State>({
    hasError: false,
  });
  const { hasError } = state;

  // 送信ボタン押下時の処理
  const onSubmit = async ({
    category,
    email1,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    textarea,
    reCaptchaResponseToken,
  }: ContactFormData): Promise<void> => {
    try {
      await axios.post(`${process.env.GATSBY_CARMO_API_BASE_PATH}/api/contact`, {
        contactType: category,
        email: email1,
        lastName,
        firstName,
        lastNameKana,
        firstNameKana,
        content: textarea,
        reCaptchaResponseToken,
      });
      await navigate('/contact/complete/');
      window.scrollTo(0, 0);
    } catch (e) {
      // エラー表示
      setState({
        hasError: true,
      });
    }
  };

  return (
    <Layout>
      <Header />
      <Breadcrumb
        items={[
          { to: NEWCAR_DEFINE.PATH.TOP, label: '新車TOP' },
          { label: 'お問合せ' },
        ]}
      />

      <main className="columns has-background-white-ter">
        <div className={`column is-full-touch is-half-desktop ${styles.contentContainer}`}>
          <section className={styles.contentSpacing}>
            <h1 className="is-size-3">お問合せ</h1>
            <ContactDescription />
          </section>

          <section>
            <SEO pageTitle="カーリースのお問い合わせ" pageDescription="定額カルモくんへのお問合せはこちらから。" />
            <ContactForm onSubmit={onSubmit} hasError={hasError} />
          </section>
        </div>
      </main>

      <Footer />
      <ScrollToTop />
    </Layout>
  );
};

export default Page;
