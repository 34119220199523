import React, { FC } from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from '@/pages/contact/index.module.styl';

const ContactDescription: FC = () => (
  <>
    <p className={`is-size-5 ${styles.contentText}`}>
      おトクにマイカー 定額カルモくんについてのご不明点は、まずは「
      <Link to={NEWCAR_DEFINE.PATH.FAQ} className={`has-text-weight-bold ${styles.qLink}`}>
        よくあるご質問
      </Link>
      」をご確認ください。店舗情報は「
      <a href={NEWCAR_DEFINE.PATH.SHOPLIST} className={`has-text-weight-bold ${styles.qLink}`}>加盟店一覧</a>
      」にてご確認いただけます。
    </p>
    <p className={`is-size-5 ${styles.contentText}`}>
      必要な回答内容が得られない場合は、以下のお問合せフォームをご利用ください。
      <br />
      お問合せ返答時間内に順次対応させていただきます。
      <br />
      お問合せ返答時間 平日10:00～19:00（土日・祝日・年末年始休み）
    </p>
    <p className={`is-size-5 ${styles.contentText}`}>
      なお、
      <a
        href="https://carmo-kun.jp/column/newcar/service_introduction_download_ss/"
        target="_blank"
        className={`has-text-weight-bold ${styles.qLink}`}
        rel="noreferrer"
      >
        資料請求はこちら
      </a>
      から、
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSf0JxT3eHk23n9yQ0xmbp3ivKbdx3xuQpeIJLF5qXqgEA_QaA/viewform"
        target="_blank"
        className={`has-text-weight-bold ${styles.qLink}`}
        rel="noreferrer"
      >
        法人・個人事業主のお客様はこちら
      </a>
      からお問い合わせください。
    </p>
  </>
);

export default ContactDescription;
